module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/builds/MostSignificantBytes/www.msbytes.com/src/cms/cms.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"MSB","short_name":"MSB","start_url":"/","background_color":"#ffffff","theme_color":"#003399","display":"standalone","icons":[{"src":"img/maskable_icon_x48.png","sizes":"48x48","type":"image/png","purpose":"any maskable"},{"src":"img/maskable_icon_x72.png","sizes":"72x72","type":"image/png","purpose":"any maskable"},{"src":"img/maskable_icon_x96.png","sizes":"96x96","type":"image/png","purpose":"any maskable"},{"src":"img/maskable_icon_x128.png","sizes":"128x128","type":"image/png","purpose":"any maskable"},{"src":"img/maskable_icon_x192.png","sizes":"192x192","type":"image/png","purpose":"any maskable"},{"src":"img/maskable_icon_x384.png","sizes":"384x384","type":"image/png","purpose":"any maskable"},{"src":"img/maskable_icon_x512.png","sizes":"512x512","type":"image/png","purpose":"any maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
